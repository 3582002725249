// External imports
import styled from '@emotion/styled'

// HOCs
import persistQueriesLink from '../hocs/persist-queries-link'

/**
 * Internal links should populate the 'href' property
 *  -> this automatically wraps it with next js link
 * External links should populate the 'exthref' property
 * Automatically persists all existing router queries
 * @param props Either 'href' or 'exthref'.
 * @returns Styled Inline link
 */
const InlineLink = persistQueriesLink(
  styled.a`
    color: inherit;
    text-decoration: none;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  `
)
export default InlineLink
