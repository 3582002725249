import type React from 'react'

import Image from './NextImage'
import { forwardRef, useState } from 'react'

import styled from '@emotion/styled'

import ChevronLeftIcon from '../../public/assets/icons/chevron-left.svg'
import ChevronRightIcon from '../../public/assets/icons/chevron-right.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Slider = styled.div`
  width: 100%;
  height: 100%;
  max-width: 750px;
  background-color: transparent;
  position: relative;
`

const SliderMask = styled.div`
  overflow: visible;
  background-color: transparent;
  box-shadow: none;
  position: relative;
  display: block;
  left: 0;
  right: 0;
  height: 100%;
  white-space: nowrap;
`

const ElementContainer = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  white-space: normal;
  text-align: left;

  padding: 0 25px;

  transition: transform .5s, opacity .5s;
`

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-100%);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;

  background: ${(props): string => props.theme.outwrite.colors.white};
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: ${(props): string => props.theme.boxShadow.secondary};

  &:hover,&:focus,&:active {
    background: ${(props): string => props.theme.outwrite.colors.gray[100]};
  }
  transition: background ${(props): string => props.theme.transitionDuration};
`

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: JSX.Element[]
}

const Carousel = forwardRef<HTMLDivElement, Props>(({ items, ...rest }, ref) => {
  const [curr, setCurr] = useState(0)

  return (
    <Wrapper {...rest} ref={ref}>
      <Slider>
        <SliderMask>
          {items.map((Element, i) => (
            <ElementContainer
              key={i}
              style={{ transform: `translateX(-${100 * curr}%)`, opacity: i !== curr ? '0.3' : '1' }}
            >
              {Element}
            </ElementContainer>
          ))}
        </SliderMask>
        {items.length > 1 && (
          <>
            <ArrowButton
              style={{ left: 0 }}
              onClick={(): void => setCurr(state => state - 1 < 0 ? items.length - 1 : state - 1)}
              aria-label="Click left to view more items"
            >
              <Image src={ChevronLeftIcon} alt='' />
            </ArrowButton>
            <ArrowButton
              style={{ right: 0 }}
              onClick={(): void => setCurr(state => state + 1 >= items.length ? 0 : state + 1)}
              aria-label="Click right to view more items"
            >
              <Image src={ChevronRightIcon} alt='' />
            </ArrowButton>
          </>
        )}
      </Slider>

    </Wrapper>
  )
})
Carousel.displayName = 'Carousel'

export default Carousel
