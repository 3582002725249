import type { TFunction } from 'react-i18next'
import type { Testimonial } from '../components/localized/Testimonials'

const getDefaultTestimonials = (t: TFunction<'common', undefined>): Testimonial[] => ([
  { ...t('testimonials.quotes.ANDY_TWOMEY'), img: { src: require('../../public/assets/reviewers/andy-twomey.png'), height: 45, width: 45 } },
  { ...t('testimonials.quotes.DEB_CARR'), img: { src: require('../../public/assets/reviewers/deb-carr.png'), height: 45, width: 45 } },
  { ...t('testimonials.quotes.SHANTELLE_ISAAKS'), img: { src: require('../../public/assets/reviewers/shantelle-isaaks.png'), height: 45, width: 45 } },
  { ...t('testimonials.quotes.NATHAN_TANG'), img: { src: require('../../public/assets/reviewers/chrome.png'), height: 45, width: 45 } },
  { ...t('testimonials.quotes.CHARLINE_J'), img: { src: require('../../public/assets/reviewers/capterra.png'), height: 45, width: 45 } }
])

export default getDefaultTestimonials
