// Type imports
import type React from 'react'
import type { OutwriteShadesBrandColor } from '@outwrite/themes'
import type { ImageProps } from 'next/image'
import type { BaseLocalizedProps } from './types'

// External imports
import Image from '../NextImage'
import styled from '@emotion/styled'
import { useInView } from 'react-intersection-observer'

// Misc
import getDefaultTestimonials from '../../util/get-default-testimonials'

import Carousel from '../Carousel'
import InlineLink from '../InlineLink'
import { CenteredSection, Title } from '../CenteredSection'

// Constant imports
import { MOBILE_WIDTH_PX, REVIEWS_PATHNAME } from '../../constants'

const ClippedSection = styled(CenteredSection)`
  overflow: hidden;
`

const Description = styled.p`
${(props): string => props.theme.outwrite.typos.default.normal[600]}
  margin: 0 0 64px 0;
  color: ${(props): string => props.theme.outwrite.colors.gray[300]};

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    ${(props): string => props.theme.outwrite.typos.default.normal[200]}
  }
`

const Link = styled(InlineLink)`
  text-decoration: underline;
  font-weight: 600;
`

const BG_COLORS: Array<keyof OutwriteShadesBrandColor> = ['yellow', 'purple', 'red']

interface WrapperProps { index: number }
const Wrapper = styled.div<WrapperProps>`
  background-color: ${(props): string => props.theme.outwrite.colors[BG_COLORS[props.index % BG_COLORS.length]][300]};
  color: ${(props): string => props.theme.textColor};
  border-radius: 15px;
  box-shadow: 0 20px 50px 0 rgb(70 137 215 / 20%);
  text-align: left;
  padding: 48px;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    padding: 24px;
  }
`

const Quote = styled.q`
  margin: 0;

  ${(props): string => props.theme.outwrite.typos.default.normal[600]}

  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    font-size: 17px;
    line-height: 18px;
  }
`

const Details = styled.div`
  margin-top: 32px;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    margin-top: 16px;
  }
  display: flex;
  align-items: center;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  border-radius: 100%;
  max-height: 80px;
  max-width: 80px;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    max-height: 24px;
    max-width: 24px;
  }
`

const Name = styled.p`
  ${(props): string => props.theme.outwrite.typos.default.normal[400]}
  font-weight: 600;
  margin: 0 0 4px 0;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    margin-bottom: 2px;
    ${(props): string => props.theme.outwrite.typos.default.normal[0]}
  }
`

const Position = styled.p`
  ${(props): string => props.theme.outwrite.typos.default.normal[300]}
  margin: 0;
  @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
    font-size: 7px;
    line-height: 11px;
  }
`

export interface Testimonial {
  readonly quote: string
  readonly name: string
  readonly position: string
  readonly img?: ImageProps
}

interface TestimonialsProps extends BaseLocalizedProps {
  readonly title?: string
  readonly description?: string
  readonly list?: Testimonial[]
}

const IN_VIEW_OPTIONS = { triggerOnce: true, initialInView: false, delay: 500, threshold: 0.7 }

const Testimonials: React.FC<TestimonialsProps> = ({ title, description, list, t, tOptions }) => {
  const { ref: textRef, inView: textInView } = useInView(IN_VIEW_OPTIONS)
  const { ref: carouselRef, inView: carouselInView } = useInView(IN_VIEW_OPTIONS)

  return (
    <ClippedSection bgColor='black'>
      <div
        ref={textRef}
        style={{
          transform: textInView ? 'translateY(0)' : 'translateY(50px)',
          opacity: textInView ? 1 : 0,
          transition: 'transform .25s cubic-bezier(0, 0, 0.2, 1), opacity .25s'
        }}
      >
        <Title>{title ?? t('testimonials.TITLE', tOptions)}</Title>
        <Description>
          {description ?? t('testimonials.DESCRIPTION', tOptions)}
          <br />
          <Link href={REVIEWS_PATHNAME}>{t('testimonials.READ_ALL_REVIEWS', tOptions)}</Link>
        </Description>
      </div>

      <Carousel
        ref={carouselRef}
        style={{
          transform: carouselInView ? 'translateY(0)' : 'translateY(50px)',
          opacity: carouselInView ? 1 : 0,
          transition: 'transform .25s cubic-bezier(0, 0, 0.2, 1), opacity .25s'
        }}
        items={(list ?? getDefaultTestimonials(t)).map(({ quote, name, position, img }, i) => (
          <Wrapper key={name} index={i}>
            <Quote>{quote}</Quote>
            <Details>
              {img && <ImageContainer><Image alt='' {...img} /></ImageContainer>}
              <div>
                <Name>{name}</Name>
                <Position>{position}</Position>
              </div>
            </Details>
          </Wrapper>
        ))}
      />
    </ClippedSection>
  )
}

export default Testimonials
